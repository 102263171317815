<template>
  <v-container class="sb-max">
    <v-row justify="center">
      <sb-page-message v-model="pageMessage" />
    </v-row>

    <v-row>
      <v-col>
        <v-divider />
      </v-col>
    </v-row>

    <template v-for="(item, headerIndex) in menuItems"
              align-content="left">
      <v-row v-if="item.homePage && hasPermission(item.roles)"
             :key="`header-${headerIndex}`">
        <div class="text-h4 my-5">
          {{ item.text }}
        </div>
      </v-row>

      <v-row v-if="item.children && hasPermission(item.roles)"
             :key="`row-${headerIndex}`">
        <template v-for="(child, itemIndex) in item.children">
          <v-card v-if="hasPermission(child.roles)"
                  :key="`item-${itemIndex}`"
                  :to="child.path"
                  :height="175"
                  :width="175"
                  tile
                  hover
                  class="mb-5 mr-5">
            <v-card-text>
              <div class="text-center">
                <v-icon :size="40"
                        color="warning"
                        class="mt-6 icon-gradient">
                  {{ child.icon }}
                </v-icon>
              </div>
              <div class="text-center mt-4 icon-text">
                {{ child.text }}
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import SbPageMessage from '@/components/core/SbPageMessage';
import MENU_ITEMS from '@/config/menuItems';

export default {
  components: {
    SbPageMessage,
  },

  data() {
    return {
      menuItems: MENU_ITEMS,
      pageMessage: {
        img: '/img/logos/logo_badge.png',
        title: 'MyBlueFleet Portal',
        message: 'Welcome to the Standard Bank MyBlueFleet Portal. To continue, please select an option below',
      },
    };
  },

  methods: {
    ...mapActions('session', ['logout']),

    hasPermission(roles) {
      return roles.filter((x) => this.authUser.groups.includes(x)).length > 0;
    },
  },
};
</script>
